// 引入 axios
import axios from "axios";
// let base = 'http://192.168.110.3:8087';
let base = 'http://www.yunyankeji.com/api';


//传送json格式的get请求
export const getRequest=(url,params)=>{
    return axios({
        method:'get',
        url:`${base}${url}`,
        data: params
    })
}
